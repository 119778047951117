import { default as indexhfdRuERLFsMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/checkout/detail-pembayaran/index.vue?macro=true";
import { default as index2yqDMvs1G2Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/checkout/ringkasan/index.vue?macro=true";
import { default as index0kMqTV0nfsMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/external-ads/index.vue?macro=true";
import { default as indexemSGk4mUenMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/payment-method/index.vue?macro=true";
import { default as index3iDQnNwNhQMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/shipping-method/index.vue?macro=true";
import { default as _91id_93BzJ8MJmVGAMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/warehouse/[id].vue?macro=true";
import { default as addNeo1jH2zi4Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/warehouse/add.vue?macro=true";
import { default as indexH5CXIkePgIMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/warehouse/index.vue?macro=true";
import { default as indexGCRyWVKo8IMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/email-verification/index.vue?macro=true";
import { default as indexg52qxebc31Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/index.vue?macro=true";
import { default as indexRW2GmpIwmQMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/login/index.vue?macro=true";
import { default as index9Rk8qfcGmkMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/logout/index.vue?macro=true";
import { default as indexwnIyYdk1Z8Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/manual-order/index.vue?macro=true";
import { default as invoice_45link0f7AbAUHiTMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/manual-order/invoice-link.vue?macro=true";
import { default as indexmNScwsrCP9Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-1/index.vue?macro=true";
import { default as indexIU4XXmWx0kMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-2/index.vue?macro=true";
import { default as indexGyp3nettfEMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-3/index.vue?macro=true";
import { default as indexaMaOrH7vlQMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-4/index.vue?macro=true";
import { default as _91id_9389bbfjHu3uMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/post-purchase-offer/[id].vue?macro=true";
import { default as add2pLZQKji6CMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/post-purchase-offer/add.vue?macro=true";
import { default as analyticsrSk35b0FnzMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/post-purchase-offer/analytics.vue?macro=true";
import { default as listAb5EKymv5hMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/post-purchase-offer/list.vue?macro=true";
import { default as _91id_931dkjWmr7B8Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/promotions/[id].vue?macro=true";
import { default as addjdH3sNuHSFMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/promotions/add.vue?macro=true";
import { default as indexEDT2cj0zDTMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/promotions/index.vue?macro=true";
import { default as indexsazUKv0mpsMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/register/index.vue?macro=true";
import { default as _91id_93Omwl8i4w5zMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/report/customer/[id].vue?macro=true";
import { default as indexT78qZWBWQMMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/report/customer/index.vue?macro=true";
import { default as index7NvfNyvqafMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/report/settlement/index.vue?macro=true";
import { default as indexnLtuui2YsnMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/report/transaction/index.vue?macro=true";
import { default as _91key_93kicI8ygeyPMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/verify/[key].vue?macro=true";
import { default as indexwpNyaR5jhwMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/wallet/index.vue?macro=true";
export default [
  {
    name: indexhfdRuERLFsMeta?.name ?? "checkout-detail-pembayaran",
    path: indexhfdRuERLFsMeta?.path ?? "/checkout/detail-pembayaran",
    meta: indexhfdRuERLFsMeta || {},
    alias: indexhfdRuERLFsMeta?.alias || [],
    redirect: indexhfdRuERLFsMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/checkout/detail-pembayaran/index.vue").then(m => m.default || m)
  },
  {
    name: index2yqDMvs1G2Meta?.name ?? "checkout-ringkasan",
    path: index2yqDMvs1G2Meta?.path ?? "/checkout/ringkasan",
    meta: index2yqDMvs1G2Meta || {},
    alias: index2yqDMvs1G2Meta?.alias || [],
    redirect: index2yqDMvs1G2Meta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/checkout/ringkasan/index.vue").then(m => m.default || m)
  },
  {
    name: index0kMqTV0nfsMeta?.name ?? "configurations-external-ads",
    path: index0kMqTV0nfsMeta?.path ?? "/configurations/external-ads",
    meta: index0kMqTV0nfsMeta || {},
    alias: index0kMqTV0nfsMeta?.alias || [],
    redirect: index0kMqTV0nfsMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/external-ads/index.vue").then(m => m.default || m)
  },
  {
    name: indexemSGk4mUenMeta?.name ?? "configurations-payment-method",
    path: indexemSGk4mUenMeta?.path ?? "/configurations/payment-method",
    meta: indexemSGk4mUenMeta || {},
    alias: indexemSGk4mUenMeta?.alias || [],
    redirect: indexemSGk4mUenMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/payment-method/index.vue").then(m => m.default || m)
  },
  {
    name: index3iDQnNwNhQMeta?.name ?? "configurations-shipping-method",
    path: index3iDQnNwNhQMeta?.path ?? "/configurations/shipping-method",
    meta: index3iDQnNwNhQMeta || {},
    alias: index3iDQnNwNhQMeta?.alias || [],
    redirect: index3iDQnNwNhQMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/shipping-method/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BzJ8MJmVGAMeta?.name ?? "configurations-warehouse-id",
    path: _91id_93BzJ8MJmVGAMeta?.path ?? "/configurations/warehouse/:id()",
    meta: _91id_93BzJ8MJmVGAMeta || {},
    alias: _91id_93BzJ8MJmVGAMeta?.alias || [],
    redirect: _91id_93BzJ8MJmVGAMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/warehouse/[id].vue").then(m => m.default || m)
  },
  {
    name: addNeo1jH2zi4Meta?.name ?? "configurations-warehouse-add",
    path: addNeo1jH2zi4Meta?.path ?? "/configurations/warehouse/add",
    meta: addNeo1jH2zi4Meta || {},
    alias: addNeo1jH2zi4Meta?.alias || [],
    redirect: addNeo1jH2zi4Meta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/warehouse/add.vue").then(m => m.default || m)
  },
  {
    name: indexH5CXIkePgIMeta?.name ?? "configurations-warehouse",
    path: indexH5CXIkePgIMeta?.path ?? "/configurations/warehouse",
    meta: indexH5CXIkePgIMeta || {},
    alias: indexH5CXIkePgIMeta?.alias || [],
    redirect: indexH5CXIkePgIMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/configurations/warehouse/index.vue").then(m => m.default || m)
  },
  {
    name: indexGCRyWVKo8IMeta?.name ?? "email-verification",
    path: indexGCRyWVKo8IMeta?.path ?? "/email-verification",
    meta: indexGCRyWVKo8IMeta || {},
    alias: indexGCRyWVKo8IMeta?.alias || [],
    redirect: indexGCRyWVKo8IMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/email-verification/index.vue").then(m => m.default || m)
  },
  {
    name: indexg52qxebc31Meta?.name ?? "index",
    path: indexg52qxebc31Meta?.path ?? "/",
    meta: indexg52qxebc31Meta || {},
    alias: indexg52qxebc31Meta?.alias || [],
    redirect: indexg52qxebc31Meta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRW2GmpIwmQMeta?.name ?? "login",
    path: indexRW2GmpIwmQMeta?.path ?? "/login",
    meta: indexRW2GmpIwmQMeta || {},
    alias: indexRW2GmpIwmQMeta?.alias || [],
    redirect: indexRW2GmpIwmQMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index9Rk8qfcGmkMeta?.name ?? "logout",
    path: index9Rk8qfcGmkMeta?.path ?? "/logout",
    meta: index9Rk8qfcGmkMeta || {},
    alias: index9Rk8qfcGmkMeta?.alias || [],
    redirect: index9Rk8qfcGmkMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/logout/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnIyYdk1Z8Meta?.name ?? "manual-order",
    path: indexwnIyYdk1Z8Meta?.path ?? "/manual-order",
    meta: indexwnIyYdk1Z8Meta || {},
    alias: indexwnIyYdk1Z8Meta?.alias || [],
    redirect: indexwnIyYdk1Z8Meta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/manual-order/index.vue").then(m => m.default || m)
  },
  {
    name: invoice_45link0f7AbAUHiTMeta?.name ?? "manual-order-invoice-link",
    path: invoice_45link0f7AbAUHiTMeta?.path ?? "/manual-order/invoice-link",
    meta: invoice_45link0f7AbAUHiTMeta || {},
    alias: invoice_45link0f7AbAUHiTMeta?.alias || [],
    redirect: invoice_45link0f7AbAUHiTMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/manual-order/invoice-link.vue").then(m => m.default || m)
  },
  {
    name: indexmNScwsrCP9Meta?.name ?? "onboarding-step-1",
    path: indexmNScwsrCP9Meta?.path ?? "/onboarding/step-1",
    meta: indexmNScwsrCP9Meta || {},
    alias: indexmNScwsrCP9Meta?.alias || [],
    redirect: indexmNScwsrCP9Meta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-1/index.vue").then(m => m.default || m)
  },
  {
    name: indexIU4XXmWx0kMeta?.name ?? "onboarding-step-2",
    path: indexIU4XXmWx0kMeta?.path ?? "/onboarding/step-2",
    meta: indexIU4XXmWx0kMeta || {},
    alias: indexIU4XXmWx0kMeta?.alias || [],
    redirect: indexIU4XXmWx0kMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-2/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyp3nettfEMeta?.name ?? "onboarding-step-3",
    path: indexGyp3nettfEMeta?.path ?? "/onboarding/step-3",
    meta: indexGyp3nettfEMeta || {},
    alias: indexGyp3nettfEMeta?.alias || [],
    redirect: indexGyp3nettfEMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-3/index.vue").then(m => m.default || m)
  },
  {
    name: indexaMaOrH7vlQMeta?.name ?? "onboarding-step-4",
    path: indexaMaOrH7vlQMeta?.path ?? "/onboarding/step-4",
    meta: indexaMaOrH7vlQMeta || {},
    alias: indexaMaOrH7vlQMeta?.alias || [],
    redirect: indexaMaOrH7vlQMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-4/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9389bbfjHu3uMeta?.name ?? "post-purchase-offer-id",
    path: _91id_9389bbfjHu3uMeta?.path ?? "/post-purchase-offer/:id()",
    meta: _91id_9389bbfjHu3uMeta || {},
    alias: _91id_9389bbfjHu3uMeta?.alias || [],
    redirect: _91id_9389bbfjHu3uMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/post-purchase-offer/[id].vue").then(m => m.default || m)
  },
  {
    name: add2pLZQKji6CMeta?.name ?? "post-purchase-offer-add",
    path: add2pLZQKji6CMeta?.path ?? "/post-purchase-offer/add",
    meta: add2pLZQKji6CMeta || {},
    alias: add2pLZQKji6CMeta?.alias || [],
    redirect: add2pLZQKji6CMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/post-purchase-offer/add.vue").then(m => m.default || m)
  },
  {
    name: analyticsrSk35b0FnzMeta?.name ?? "post-purchase-offer-analytics",
    path: analyticsrSk35b0FnzMeta?.path ?? "/post-purchase-offer/analytics",
    meta: analyticsrSk35b0FnzMeta || {},
    alias: analyticsrSk35b0FnzMeta?.alias || [],
    redirect: analyticsrSk35b0FnzMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/post-purchase-offer/analytics.vue").then(m => m.default || m)
  },
  {
    name: listAb5EKymv5hMeta?.name ?? "post-purchase-offer-list",
    path: listAb5EKymv5hMeta?.path ?? "/post-purchase-offer/list",
    meta: listAb5EKymv5hMeta || {},
    alias: listAb5EKymv5hMeta?.alias || [],
    redirect: listAb5EKymv5hMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/post-purchase-offer/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_931dkjWmr7B8Meta?.name ?? "promotions-id",
    path: _91id_931dkjWmr7B8Meta?.path ?? "/promotions/:id()",
    meta: _91id_931dkjWmr7B8Meta || {},
    alias: _91id_931dkjWmr7B8Meta?.alias || [],
    redirect: _91id_931dkjWmr7B8Meta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/promotions/[id].vue").then(m => m.default || m)
  },
  {
    name: addjdH3sNuHSFMeta?.name ?? "promotions-add",
    path: addjdH3sNuHSFMeta?.path ?? "/promotions/add",
    meta: addjdH3sNuHSFMeta || {},
    alias: addjdH3sNuHSFMeta?.alias || [],
    redirect: addjdH3sNuHSFMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/promotions/add.vue").then(m => m.default || m)
  },
  {
    name: indexEDT2cj0zDTMeta?.name ?? "promotions",
    path: indexEDT2cj0zDTMeta?.path ?? "/promotions",
    meta: indexEDT2cj0zDTMeta || {},
    alias: indexEDT2cj0zDTMeta?.alias || [],
    redirect: indexEDT2cj0zDTMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsazUKv0mpsMeta?.name ?? "register",
    path: indexsazUKv0mpsMeta?.path ?? "/register",
    meta: indexsazUKv0mpsMeta || {},
    alias: indexsazUKv0mpsMeta?.alias || [],
    redirect: indexsazUKv0mpsMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Omwl8i4w5zMeta?.name ?? "report-customer-id",
    path: _91id_93Omwl8i4w5zMeta?.path ?? "/report/customer/:id()",
    meta: _91id_93Omwl8i4w5zMeta || {},
    alias: _91id_93Omwl8i4w5zMeta?.alias || [],
    redirect: _91id_93Omwl8i4w5zMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/report/customer/[id].vue").then(m => m.default || m)
  },
  {
    name: indexT78qZWBWQMMeta?.name ?? "report-customer",
    path: indexT78qZWBWQMMeta?.path ?? "/report/customer",
    meta: indexT78qZWBWQMMeta || {},
    alias: indexT78qZWBWQMMeta?.alias || [],
    redirect: indexT78qZWBWQMMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/report/customer/index.vue").then(m => m.default || m)
  },
  {
    name: index7NvfNyvqafMeta?.name ?? "report-settlement",
    path: index7NvfNyvqafMeta?.path ?? "/report/settlement",
    meta: index7NvfNyvqafMeta || {},
    alias: index7NvfNyvqafMeta?.alias || [],
    redirect: index7NvfNyvqafMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/report/settlement/index.vue").then(m => m.default || m)
  },
  {
    name: indexnLtuui2YsnMeta?.name ?? "report-transaction",
    path: indexnLtuui2YsnMeta?.path ?? "/report/transaction",
    meta: indexnLtuui2YsnMeta || {},
    alias: indexnLtuui2YsnMeta?.alias || [],
    redirect: indexnLtuui2YsnMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/report/transaction/index.vue").then(m => m.default || m)
  },
  {
    name: _91key_93kicI8ygeyPMeta?.name ?? "verify-key",
    path: _91key_93kicI8ygeyPMeta?.path ?? "/verify/:key()",
    meta: _91key_93kicI8ygeyPMeta || {},
    alias: _91key_93kicI8ygeyPMeta?.alias || [],
    redirect: _91key_93kicI8ygeyPMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/verify/[key].vue").then(m => m.default || m)
  },
  {
    name: indexwpNyaR5jhwMeta?.name ?? "wallet",
    path: indexwpNyaR5jhwMeta?.path ?? "/wallet",
    meta: indexwpNyaR5jhwMeta || {},
    alias: indexwpNyaR5jhwMeta?.alias || [],
    redirect: indexwpNyaR5jhwMeta?.redirect || undefined,
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/wallet/index.vue").then(m => m.default || m)
  }
]