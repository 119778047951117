// 3rd's
import { $fetch, type FetchOptions } from "ofetch";

// locals
import MerchantModule from "~/repository/modules/merchant";
import ReportModule from "~/repository/modules/report";
import PromotionModule from "~/repository/modules/promotion";
import PPOModule from "~/repository/modules/ppo";
import WarehouseModule from "~/repository/modules/warehouse";
import ManualModule from "~/repository/modules/manual";
import PaymentModule from "~/repository/modules/payment";
import WalletModule from "~/repository/modules/wallet";

interface IApiInstance {
  merchant: MerchantModule;
  report: ReportModule;
  promotion: PromotionModule;
  ppo: PPOModule;
  warehouse: WarehouseModule;
  manual: ManualModule;
  payment: PaymentModule;
  wallet: WalletModule;
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const fetchOptions: FetchOptions = {
    baseURL: config.public.API_URL,
  };

  // Create a new instance of $fecther with custom option
  const apiFecther = $fetch.create(fetchOptions);

  // An object containing all repositories we need to expose
  const modules: IApiInstance = {
    merchant: new MerchantModule(apiFecther),
    report: new ReportModule(apiFecther),
    promotion: new PromotionModule(apiFecther),
    ppo: new PPOModule(apiFecther),
    warehouse: new WarehouseModule(apiFecther),
    manual: new ManualModule(apiFecther),
    payment: new PaymentModule(apiFecther),
    wallet: new WalletModule(apiFecther),
  };

  return {
    provide: {
      api: modules,
    },
  };
});
