// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";
import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class ReportModule extends FetchFactory<IResponse> {
  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  getAvailableBalance() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.LEDGERSVC}/v1/seller-dash/report/balance/active`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getTransactionCard(payload: IPayloadDateRange) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/stats/transaction-card?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getSalesActivity(payload: IPayloadCard) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/stats/sales-activity?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getSettlementSummary(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.LEDGERSVC}/v1/seller-dash/report/disbursement?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getSettlementDetail(payload: IPayloadSettlementDetail) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/settlements?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getTransaction(payload: IPayloadSettlementDetail) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/transactions?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  exportTransaction(payload: IPayloadSettlementDetail) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/transactions/report?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  exportSettlement(payload: IPayloadSettlementDetail) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/settlements/report?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getCouriers() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.SHIPPINGSVC}/v1/couriers`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getPaymentMethod() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.PAYMENTSVC}/v1/pym/list?hdo=false&hdg=false&with_cc_installment=1`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getPaymentMethodReport(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/stats/payment?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getShippingMethodReport(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/stats/courier?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getCustomers(payload: IPayloadCustomer) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.ORDERSVC}/v1/seller-dash/customer-report/shoppers?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  customerExport(payload: IPayloadCustomer) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/seller-dash/customers/report?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getDestinationCity(payload: IPayloadCity) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.ORDERSVC}/v1/seller-dash/customer-report/orders/destination-cities?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getShopper(id) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.SHOPPERSVC}/v1/seller-dash/customer-report/shoppers/${id}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getActiveCart(id) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.CHECKOUTSVC}/v1/seller-dash/customer-report/cart/shoppers/${id}/active`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getLatestCart(id) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.ORDERSVC}/v1/seller-dash/customer-report/orders/shoppers/${id}/latest-order`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default ReportModule;
