// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class PPOModule extends FetchFactory<IResponse> {
  // private RESOURCE = '/products';

  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  getTable(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.PROMOSVC}/v1/ppos/manage?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getReportTable(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/ppos?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getReportCard() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.REPORTSVC}/v1/ppos/card`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getDetail(id) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.PROMOSVC}/v1/ppos/manage/${id}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getProducts(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.PROMOSVC}/v1/ppos/manage/products?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  post(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.PROMOSVC}/v1/ppos/manage`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  patch(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PATCH",
      `${config.PROMOSVC}/v1/ppos/manage/${payload.id}`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  delete(id) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "DELETE",
      `${config.PROMOSVC}/v1/ppos/manage/${id}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default PPOModule;
