import revive_payload_client_YTGlmQWWT5 from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xKuw1km5sV from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_l138bYv0gX from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_10gaYeCiJK from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import apexchart_client_5d6ra58DX6 from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/apexchart.client.ts";
import api_GFfDXud5Cr from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/api.ts";
import gmap_pjGla1KF16 from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/gmap.ts";
import notify_client_jkCTjllQJP from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/notify.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_l138bYv0gX,
  chunk_reload_client_SeG0EjL5Ec,
  check_outdated_build_client_LIYcCMJD18,
  plugin_10gaYeCiJK,
  apexchart_client_5d6ra58DX6,
  api_GFfDXud5Cr,
  gmap_pjGla1KF16,
  notify_client_jkCTjllQJP
]