<template>
  <div class="flex flex-col items-center justify-center h-screen w-full">
    <span class="text-8xl font-bold text-primary">{{ error.statusCode }}</span>
    <h3 class="text-3xl mt-4 font-bold">{{ message(error).title }}</h3>
    <!-- <p v-if="config.APP_DEBUG !== 'on'" class="mt-4 text-lg text-gray-500">{{ message(error).desc }}</p> -->
    <pre class="mt-4 text-lg text-gray-500">{{ message(error).desc }}</pre>
    <button
      v-if="error.statusCode === 500"
      type="button"
      class="mt-4 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-primary rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      @click="handleClearError"
    >
      <svg
        class="w-3 h-3 text-white mr-2"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 16"
      >
        <path
          d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"
        />
        <path
          d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"
        />
      </svg>
      Contact Support
    </button>
    <nuxt-link
      v-else
      to="/"
      class="mt-4 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-primary rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    >
      <svg
        class="mr-2 w-3 h-3 text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9"
        />
      </svg>
      Dashboard
    </nuxt-link>
  </div>
</template>

<script setup>
defineProps({
  error: {
    type: Object,
    required: true,
  },
});

definePageMeta({
  layout: "error",
});

useHead({
  title: `Error`,
});

const handleClearError = () => clearError({ redirect: "/" });

const message = (error) => {
  const config = useRuntimeConfig().public;
  if (error.statusCode === 404) {
    return {
      title: `Something's missing.`,
      desc: `Sorry, we can't find that page.`,
    };
  } else if (error.statusCode === 500) {
    if (config.APP_DEBUG === "on") {
      return {
        title: `Error.`,
        desc: error.data,
      };
    }
    return {
      title: `Internal Server Error.`,
      desc: `We are already working to solve the problem.`,
    };
  } else {
    return {
      title: error.message,
      desc: "",
    };
  }
};
</script>
