// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class PaymenModule extends FetchFactory<IResponse> {
  // private RESOURCE = '/products';

  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  get() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.PAYMENTSVC}/v1/seller-dash/payment-config/payment-methods`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  patch(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${config.PAYMENTSVC}/v1/seller-dash/payment-config/payment-methods`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  getBankFee() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.PAYMENTSVC}/v1/seller-dash/banks/fees`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getBankList(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.PAYMENTSVC}/v1/seller-dash/banks?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default PaymenModule;
