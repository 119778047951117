import { defineStore } from "pinia";

interface UserPayloadInterface {
  email: string;
  password: string;
}

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authenticated: false,
    user: undefined,
    loading: false,
    expired_otp: 0,
  }),
  actions: {
    async login({ email, password }: UserPayloadInterface) {
      const api_url = useRuntimeConfig().public.API_URL;
      const payload = {
        email,
        password,
      };
      // useFetch from nuxt 3
      const { status, data, error } = await $fetch(
        "/auth/v1/authentication/merchant-user/login",
        {
          baseURL: api_url,
          method: "POST",
          body: payload,
          headers: { "Content-Type": "application/json" },
        },
      ).catch((err) => err);

      if (status === "success") {
        const decodeJWT = JSON.parse(
          atob(data.access_token.split(".")[1]).toString(),
        );
        const token = {
          atoken: data.access_token,
          rtoken: data.refresh_token,
          merchant_login_id: decodeJWT.sub,
          email: decodeJWT.email,
        };
        const tokenCookies = useCookie("token"); // useCookie new hook in nuxt 3
        tokenCookies.value = data.access_token; // set token to cookie
        this.authenticated = true; // set authenticated  state value to true
        this.user = token; // set authenticated  state value to true
      }
      return { status, data, error };
    },
    verify({ access_token, refresh_token }: UserPayloadInterface) {
      const decodeJWT = JSON.parse(atob(access_token.split(".")[1]).toString());
      const token = {
        atoken: access_token,
        rtoken: refresh_token,
        merchant_login_id: decodeJWT.sub,
        email: decodeJWT.email,
      };
      const tokenCookies = useCookie("token"); // useCookie new hook in nuxt 3
      tokenCookies.value = access_token; // set token to cookie
      this.authenticated = true; // set authenticated  state value to true
      this.user = token; // set authenticated  state value to true
    },
    async refresh(refresh_token: string) {
      const api_url = useRuntimeConfig().public.API_URL;
      const payload = {
        refresh_token: refresh_token,
      };
      // useFetch from nuxt 3
      const { status, data, error } = await $fetch(
        "/auth/v1/authentication/merchant-user/refresh",
        {
          baseURL: api_url,
          method: "POST",
          body: payload,
          headers: { "Content-Type": "application/json" },
        },
      ).catch((err) => err);

      if (status === "success") {
        const decodeJWT = JSON.parse(
          atob(data.access_token.split(".")[1]).toString(),
        );
        const token = {
          atoken: data.access_token,
          rtoken: data.refresh_token,
          merchant_login_id: decodeJWT.sub,
          email: decodeJWT.email,
        };
        const tokenCookies = useCookie("token"); // useCookie new hook in nuxt 3
        tokenCookies.value = data.access_token; // set token to cookie
        this.authenticated = true; // set authenticated  state value to true
        this.user = token; // set authenticated  state value to true
      }
      return { status, data, error };
    },
    logout() {
      const token = useCookie("token"); // useCookie new hook in nuxt 3
      this.authenticated = false; // set authenticated  state value to false
      token.value = null; // clear the token cookie
      this.user = undefined; // clear the token cookie
    },
    updateExpired(value) {
      this.expired_otp = value;
    },
  },
  persist: true,
});
