// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";
import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class WalletModule extends FetchFactory<IResponse> {
  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */

  getWalletHistory(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.LEDGERSVC}/v1/seller-dash/wallet/histories?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  postWithdraw(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.PAYMENTSVC}/v1/seller-dash/withdraw`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  postTopup(data) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.CHECKOUTSVC}/v1/cart/topup-wallet`,
      data,
      fetchOptions,
    ).catch((err) => err);
  }

  postOtpWallet(){
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.API_URL}/authentication/v1/seller-dash/finance/otp/send`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  postOtpValidateWallet(data){
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.API_URL}/authentication/v1/seller-dash/finance/otp/validate`,
      data,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default WalletModule;
