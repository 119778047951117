// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class WarehouseModule extends FetchFactory<IResponse> {
  // private RESOURCE = '/products';

  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  getTable(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v1/dashboard/merchant-warehouse/list?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getDetail(id) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v1/dashboard/merchant-warehouse/${id}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  post(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.MERCHANTSVC}/v1/dashboard/merchant-warehouse`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  put(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${config.MERCHANTSVC}/v1/dashboard/merchant-warehouse/${payload.merchant_id}`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  delete(id) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "DELETE",
      `${config.MERCHANTSVC}/v1/dashboard/merchant-warehouse/${id}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default WarehouseModule;
