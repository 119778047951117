// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class ManualModule extends FetchFactory<IResponse> {
  // private RESOURCE = '/products';

  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  getTable(payload) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/merchant-dashboard/manual-orders?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getShopperByPhone(phone) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${SHOPPERSVC}/v1/seller-dash/shoppers?search_phone=${phone}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  createShopper(payload) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${SHOPPERSVC}/v1/seller-dash/shoppers`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  updateShopper(payload, id) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${SHOPPERSVC}/v1/seller-dash/shoppers/${id}`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  createOrder(payload) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${CHECKOUTSVC}/v1/merchant-dashboard/manual-orders`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default ManualModule;
